@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-black: #1f2305;
    --color-white: #cbbfbb;
    --color-white-hover-dark: #b8a8a3;
    --color-brown: #51433e;
    --color-brown-light: #736059;
    --color-green: #6f7c12;
    --color-blue: #4464ad;
    --color-blue-light: #899fd1;
    --color-yellow: #eab464;
    --color-red: #e03616;

    --color-background: var(--color-brown);
    --color-input-border: var(--color-black);
    --color-input-border-active: var(--color-blue-light);
    --color-input-fill: var(--color-brown-light);
    --color-input-text: var(--color-white);
    --color-input-shadow: var(--color-black);
    --color-input-shadow-active: var(--color-blue-light);
    --color-tag-text: var(--color-white);
    --color-tag-close-button-hover: var(--color-white-hover-dark);
    --color-tag-background: var(--color-blue);
    --color-dropdown-background: var(--color-white);
    --color-dropdown-text: var(--color-black);
    --color-dropdown-selected-item-background: var(--color-blue-light);
    --color-dropdown-selected-item-text: var(--color-blue);
    --color-active-partial-border: var(--color-blue-light);
    --color-inactive-partial-border: var(--color-black);
    --color-partial-fill: var(--color-brown-light);
    --color-partial-text: var(--color-white);
    --color-inset-question-fill: var(--color-blue);
    --color-inset-question-border: var(--color-blue);
    --color-inset-question-text: var(--color-white);
    --color-hint-fill: var(--color-green);
    --color-hint-border: var(--color-green);
    --color-hint-text: var(--color-white);
    --color-partial-text-selection-background: var(--color-blue);
    --color-partial-text-selection-text: var(--color-white);
    --color-partial-inset-question-selection-background: var(--color-white);
    --color-partial-inset-question-selection-text: var(--color-blue);

    --color-button-regular: var(--color-white);
    --color-button-confirm: var(--color-green);

    --font-base: 'Source Sans Pro', sans-serif;
    --font-code: 'Source Code Pro', mono;

    --border-radius: 4px;
  }
}

html,
body {
  height: 100%;
  background-color: var(--color-background);

  @media (prefers-color-scheme: dark) {
    background-color: var(--color-background);
  }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  color: var(--color-black);
  font-family: var(--font-base);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-base);
  text-transform: uppercase;
}

.offscreen {
  position: absolute;
  left: -9999px;
}

.kn-base-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;

  border: 2px solid var(--color-black);
  border-radius: var(--border-radius);
  box-shadow: 0 2px 2px -1px var(--color-input-shadow);

  cursor: pointer;

  &:hover, &:active, &:focus {
    border: 2px solid var(--color-active-partial-border);
    box-shadow: 0 2px 2px -1px var(--color-input-shadow-active);
  }
}

.dialog-reorder-card-in-series {
  #items {
    .sortable-chosen {
      &:not(:first-child) {
        margin-top: -1px;
      }

      background-color: var(--color-brown-light);
      border-top: 1px solid var(--color-black);
      border-bottom: 1px solid var(--color-black);
    }

    .sortable-drag {
      border-right: 1px solid var(--color-black);
      border-left: 1px solid var(--color-black);
    }
  }
}